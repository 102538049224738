import React, { useState } from "react";
import {
  AspectRatio,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  Input,
  FlexGrid,
  Textarea,
  Accordion,
} from "../../components/core";
/** @jsx jsx */
import { jsx, Box, useThemeUI } from "theme-ui";
import Page from "../../components/Page";
import { Parallax } from "../../components/animate";
import { alpha } from "@theme-ui/color";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import { BackgroundVague, Vague } from "../../components/svg";
import SEO from "../../components/SEO";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { getImage } from "gatsby-plugin-image";
import Select from "react-select";
import useThemeColor from "../../hooks/useThemeColor";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Contact = ({ data }) => {
  const { theme } = useThemeUI();
  const [form, setForm] = useState(null);
  const [formState, setFormState] = useState("INITIAL");
  const primaryColor = useThemeColor("primary");
  const textColor = useThemeColor("text");

  const frontmatter = data.markdownRemark.frontmatter;

  const handleChange = (name, value) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const [openAccordion, setOpenAccordion] = useState(null);

  const handleClickAccordion = (number) => {
    setOpenAccordion(openAccordion === number ? null : number);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formElem = e.target;
    setFormState("SENDING");

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formElem.getAttribute("name"),
        ...form,
      }),
    })
      .then(() => {
        setFormState("SENT");
      })
      .catch((error) => {
        setFormState("ERROR");
        console.log("error form", error);
      });
  };

  const sujets = frontmatter.form.subjects.map((sujet) => ({
    value: sujet,
    label: sujet,
  }));

  const customStyles = {
    control: (provided, state) => ({
      // ...provided,
      ...theme.forms.select,
      borderColor: state.isFocused ? primaryColor : "#EAF1F3",
      cursor: "pointer",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "15px 17px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textColor,
      margin: 0,
    }),
    option: (provided, { isFocused, isSelected }) => ({
      fontFamily: "Montserrat",
      padding: "12px 15px",
      color: textColor,
      cursor: "pointer",
      backgroundColor: isFocused && alpha("primary", 0.25)(theme),
      ":active": {
        backgroundColor: alpha("primary", 0.5)(theme),
      },
    }),
    placeholder: () => ({
      color: "#9A9A9A",
    }),
    indicatorSeparator: () => ({}),
  };

  return (
    <Page>
      <SEO
        title="Contact"
        description="Vous aimeriez en savoir plus sur nos actions et nous rejoindre lors
            de nos prochaines activités ? Vous souhaitez devenir l’un de nos
            partenaires ? Nous vous invitons à nous contacter via ce formulaire."
      />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête contact"
          image={getImage(frontmatter.image)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
            >
              <Heading as="h1" variant="h1" color="white">
                Contact
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Container
        as="section"
        sx={{
          paddingTop: [8, null, 10],
          paddingBottom: 5,
        }}
      >
        <Box>
          <Box>
            <Heading as="h2" color="secondary" sx={{ marginBottom: 2 }}>
              {frontmatter.faq.title}
            </Heading>
            <Text preLine>{frontmatter.faq.intro}</Text>
            <Vague
              color="primary"
              sx={{
                marginTop: 6,
                marginBottom: [8, null, null],
              }}
            />
          </Box>
          <Flex direction="column" gap={4}>
            {frontmatter.faq.questions &&
              frontmatter.faq.questions.map(({ question, answer }, index) => (
                <Accordion
                  key={index}
                  title={question}
                  isOpen={openAccordion === index}
                  onClick={() => handleClickAccordion(index)}
                >
                  {answer}
                </Accordion>
              ))}
          </Flex>
        </Box>
      </Container>
      <BackgroundVague
        width="100%"
        height={null}
        color="background3"
        sx={{
          bottom: "-1px",
          transform: "scaleX(-1) rotate(180deg)",
        }}
      />
      <Box
        as="section"
        sx={{
          backgroundColor: "background3",
          py: [8, null, 13],
        }}
      >
        <Container>
          <Box>
            <Heading as="h2" color="secondary" sx={{ marginBottom: 2 }}>
              {frontmatter.form.title}
            </Heading>
            <Text preLine>{frontmatter.form.intro}</Text>
            <Vague
              color="primary"
              sx={{
                marginTop: 6,
                marginBottom: 7,
              }}
            />
          </Box>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <FlexGrid
              gutterX={3}
              gutterY={4}
              columns={[12, 6, 6]}
              sx={{ marginBottom: 4 }}
            >
              <Box>
                <Input
                  id="lastname"
                  name="nom"
                  placeholder="Nom"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  required
                />
              </Box>
              <Box>
                <Input
                  id="firstname"
                  name="prenom"
                  placeholder="Prénom"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  required
                />
              </Box>
              <Box>
                <Input
                  id="mail"
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  required
                />
              </Box>
              <Box>
                <Input
                  id="phone"
                  name="telephone"
                  type="tel"
                  placeholder="Téléphone (optionnel)"
                  pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Box>
            </FlexGrid>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Select
                placeholder="Sujet"
                options={sujets}
                styles={customStyles}
                isSearchable={false}
                onChange={({ value }) => {
                  handleChange("sujet", value);
                }}
              />
              <input
                tabIndex={-1}
                autoComplete="off"
                id="sujet"
                name="sujet"
                style={{
                  opacity: 0,
                  height: 0,
                  position: "absolute",
                  left: "10%",
                  cursor: "default",
                }}
                defaultValue={
                  form && form.hasOwnProperty("sujet") ? form["sujet"] : ""
                }
                required
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </Box>
            <Textarea
              id="content"
              name="contenu"
              placeholder="Contenu"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              rows={8}
              sx={{ marginTop: "30px", marginBottom: "40px", resize: "none" }}
              required
            />
            <Flex
              direction={["column", "row", null]}
              positions={[
                ["center", "center"],
                ["space-between", "center"],
                null,
              ]}
            >
              <Box>
                {formState === "SENDING" && (
                  <Loader
                    type="ThreeDots"
                    color={theme.colors.primary}
                    height={20}
                    width={40}
                  />
                )}
                {formState === "SENT" && (
                  <Text
                    color="#218838"
                    sx={{ fontSize: 2, fontWeight: "bold" }}
                  >
                    Votre message a bien été transmis !
                  </Text>
                )}
                {formState === "ERROR" && (
                  <Text
                    color="#dc3545"
                    sx={{ fontSize: 2, fontWeight: "bold" }}
                  >
                    Une erreur s'sest produite merci de réessayer ultérieurement
                  </Text>
                )}
              </Box>
              <Button
                id="btn-send-form"
                sx={{
                  width: "100%",
                  maxWidth: ["240px", "169px", null],
                  height: "50px",
                  textTransform: "uppercase",
                }}
                type="submit"
                disabled={formState !== "INITIAL"}
              >
                Envoyer
              </Button>
            </Flex>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    markdownRemark(fields: { slug: { eq: "/contact/" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        faq {
          title
          intro
          questions {
            question
            answer
          }
        }
        form {
          title
          intro
          subjects
        }
      }
    }
  }
`;
